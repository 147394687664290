import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../constants";
import "./admin.css";

export default function AddUserForm({ rafraichir, token }) {
  const [inscriptionUser, setInscriptionUser] = useState({ mail: "", mdp: "" });
  const navigate = useHistory();
  const [messageMailUser, setMessageMailUser] = useState("");
  const [messageMailInvalide, setMessageMailInvalide] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [twoFactorEnabled, setTwoFactorEnabled] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setMessageMailUser("");
      setMessageMailInvalide("");
    }, 5000);
  }, [messageMailUser, messageMailInvalide]);

  const handleChange = (event) => {
    const { name, type, checked, value } = event.target;
    if (type === "checkbox") {
      if (name === "isAdmin") setIsAdmin(checked);
      if (name === "twoFactorEnabled") setTwoFactorEnabled(checked);
    } else {
      setInscriptionUser({ ...inscriptionUser, [name]: value });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inscriptionUser.mail)) {
      setMessageMailInvalide("Adresse e-mail invalide");
      return;
    }

    try {
      const res = await axios.post(
        `${API_URL}/user/signupadmin`,
        { email: inscriptionUser.mail, isAdmin, twoFactorEnabled },
        { headers: { Authorization: "Bearer " + token } }
      );
      setMessageMailUser(res.data.message);
      rafraichir();
      setInscriptionUser({ mail: "", mdp: "" });
    } catch (error) {
      setMessageMailInvalide(error.response?.data?.error || "Une erreur est survenue");
    }
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col justify-center text-center">
      <label className="flex flex-col text-base lg:text-2xl text-center text-bleuSTB 3xl:text-3xl">
        Ajouter un Utilisateur
        <input
          className="text-sm md:text-base lg:text-xl 3xl:text-2xl border-4 border-bleuCielSTB hover:border-bleuSTB rounded-2xl p-4 text-center mt-4 mb-4"
          type="mail"
          name="mail"
          placeholder="Adresse mail"
          onChange={handleChange}
          value={inscriptionUser.mail}
        />
      </label>
      <label className="block text-center text-base 3xl:text-xl  lg:text-left mb-4">
        <span className="titreCheckbox">Admin</span>
        <input
          type="checkbox"
          name="isAdmin"
          checked={isAdmin}
          onChange={handleChange}
          className="ml-4"
        />
      </label>
      <label className="block text-center text-base 3xl:text-xl  lg:text-left mb-4">
        Activer la double authenfication<input type="checkbox" name="twoFactorEnabled" checked={twoFactorEnabled} onChange={handleChange} />
      </label>
      <div className="flex justify-center">
        <input 
          type="submit" 
          value="Ajouter" 
          className="bg-bleuSTB hover:bg-marronSTB text-white lg:text-sm 3xl:text-xl 
                    border-none rounded-full p-2 w-11/12 lg:w-60 cursor-pointer"
        />
      </div>
      {messageMailUser && <p className="bg-green-500 rounded-lg text-white mx-auto p-2 text-center my-4 text-xl flex items-center justify-center">{messageMailUser}</p>}
      {messageMailInvalide && <p className="bg-error rounded-lg text-white text-xl mx-auto p-2 text-center mt-8 flex items-center justify-center">{messageMailInvalide}</p>}
    </form>

  );
}