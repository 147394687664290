import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import useModal from "../Modal/useModal";
import axios from "axios";
import { API_URL } from "../constants";
import Error from "../Error/Error";
import ForgotPassword from './ForgotPassword'
import Cookies from "js-cookie";

export default function LoginForm(props) {
  const [user, setUser] = useState({ mail: "", password: "" });
  const [twoFactorCode, setTwoFactorCode] = useState("");
  const [userId, setUserId] = useState("");
  const [tempToken, setTempToken] = useState(null);
  const [stayConnected, setStayConnected] = useState(false);
  const [rememberDevice, setRememberDevice] = useState(false);
  const [qrCode, setQrCode] = useState(null);
  const [secret, setSecret] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useHistory();


  const { isShowing: isForgotPasswordModalShowing, toggle: toggleForgotPasswordModal } = useModal();

  const handleForgotPassword = () => {
    toggleForgotPasswordModal();
  };


  useEffect(() => {
    setTimeout(() => {
      setError("");
    }, 10000);
  }, [error]);

  const handleUser = (event) => setUser({ ...user, [event.target.name]: event.target.value });
  const handleCheckbox = () => setStayConnected(!stayConnected);
  const handle2FAChange = (event) => setTwoFactorCode(event.target.value);
  const handleRememberDevice = () => setRememberDevice(!rememberDevice);

  const storeUserInLocalStorage = (userData) => {
    localStorage.setItem("user", JSON.stringify(userData));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    if (user.mail !== "" && user.password !== "") {
      try {
        let loginData = { email: user.mail, password: user.password };
  
        // 🔹 Faire la requête de login
        let response = await axios.post(`${API_URL}/user/login`, loginData);
        
        setUserId(response.data.userId); // ✅ Stocker userId
  
        // 🔹 Récupérer le token 2FA après que `userId` est défini
        const stored2faToken = Cookies.get(`2fa_token_${response.data.userId}`);
        console.log("Token récupéré :", stored2faToken);
  
        // 🔹 Si un token 2FA est trouvé, refaire la requête login avec ce token
        if (stored2faToken) {
          loginData.twoFaToken = stored2faToken;
          response = await axios.post(`${API_URL}/user/login`, loginData);
        }
  
        if (stayConnected) {
          storeUserInLocalStorage(response.data);
        }
  
        if (response.data.require2FA) {
          localStorage.setItem("token", response.data.token);
          setTempToken(response.data.token);
  
          setTimeout(() => {
            fetchQrCode(response.data.token);
          }, 300);
        } else {
          props.setUser(response.data);
          navigate.push("/motorsList");
        }
      } catch (error) {
        setError("Identifiants incorrects.");
      }
    } else {
      setError("Veuillez remplir tous les champs.");
    }
  };
  
  
  const fetchQrCode = async (token) => {
    try {
      const response = await axios.post(
        `${API_URL}/user/enable-2fa`,
        {},
        { headers: { Authorization: `Bearer ${token}` } } // ✅ Envoie un vrai token JWT
      );
  
      setQrCode(response.data.qrCode);
      setSecret(response.data.secret);
    } catch (error) {
      setError("Impossible de récupérer le QR Code.");
      console.error("Erreur 2FA :", error.response?.data || error.message);
    }
  };

  const handleVerify2FA = async (event) => {
    event.preventDefault();
    if (!twoFactorCode) return setError("Veuillez entrer le code 2FA.");
  
    try {
      const response = await axios.post(
        `${API_URL}/user/login-2fa`,
        { token: twoFactorCode, rememberDevice },
        { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } }
      );
  
      props.setUser(response.data);
  
      if (rememberDevice) {
        console.log(response.data.user);
        Cookies.set(`2fa_token_${response.data.user._id}`, response.data["2fa_token"], { 
          expires: 7, 
          secure: true, 
          sameSite: "Strict" 
        });
      }
  
      navigate.push("/motorsList");
    } catch (error) {
      setError("Code 2FA incorrect.");
    }
  }
  

  return (
    <div className="flex flex-col border-4 border-bleuSTB rounded-2xl text-center p-12 w-11/12 sm:w-1/2">
      <h2 className="text-bleuSTB font-bold text-3xl">Se connecter</h2>

      {!tempToken ? (
        <form className="flex flex-col w-full m-auto mt-6 " onSubmit={handleSubmit}>
        <label className="flex flex-col text-center sm:text-center sm:mb-2 sm:-mt-1 sm:text-sm xl:text-left xl:text-3xl 3xl:text-5xl mb-4">
          E-Mail
          <input
            type="mail"
            name="mail"
            placeholder="E-Mail"
            onChange={handleUser}
            value={user.mail}
            className="text-xs border-solid border-2 border-bleuCielSTB hover:border-bleuSTB rounded-2xl p-1 xl:p-5 text-center mt-2 xl:text-2xl 3xl:text-4xl"
          />
        </label>

        <label className="flex flex-col text-center sm:text-center sm:mb-2 sm:-mt-1 sm:text-sm xl:text-left xl:text-3xl 3xl:text-5xl mb-4">
          Mot de passe
          <input
            type="password"
            name="password"
            placeholder="Mot de passe"
            onChange={handleUser}
            value={user.password}
            className="text-xs border-solid border-2 border-bleuCielSTB hover:border-bleuSTB rounded-2xl p-1 xl:p-5 text-center mt-2 xl:text-2xl 3xl:text-4xl"
          />
        </label>

        <label className="block text-center text-xs sm:text-center sm:mb-2 sm:-mt-1 sm:text-base xl:text-left xl:text-3xl 3xl:text-4xl mb-6 xl:mt-2">
  Rester connecté
          <input
            type="checkbox"
            name="checkbox"
            onChange={handleCheckbox}
            checked={stayConnected}
            className="ml-4"
          />
          <span className="inline-block w-full float-none mt-10 text-bleuSTB text-center sm:-mb-1 xl:text-2xl 3xl:text-4xl cursor-pointer xl:w-fit xl:float-right xl:mt-0 hover:text-marronSTB hover:underline " onClick={handleForgotPassword}>
            Mot de passe oublié
          </span>
        </label>

        <input type="submit" value="Se Connecter" className="submitButton bg-bleuSTB hover:bg-marronSTB text-white text-xs sm:text-sm 3xl:text-4xl py-2 px-4 rounded-full xl:w-1/3 sm:w-32 mx-auto mt-4 mb-1 cursor-pointer" />

        </form>
      ) : (
        <form className="flex flex-col w-full mt-6" onSubmit={handleVerify2FA}>
        {qrCode && secret ? (
          <div className="text-center p-6">
            <h3 className="text-xl lg:text-2xl font-bold text-gray-800 mb-4">
               Activez votre double authentification (2FA)
            </h3>
            <p className="text-lg lg:text-xl text-gray-700 mb-6 leading-relaxed">
              Sécurisez votre compte en suivant ces étapes :
            </p>

            <ul className="text-lg text-left mb-6 space-y-3 list-disc list-inside">
              <li>Scannez le QR code ci-dessous avec une application de gestion de 2FA, comme Google Authenticator ou Authy.</li>
              <li>Si vous ne pouvez pas scanner le QR code, entrez la clé secrète manuellement dans l'application.</li>
              <li>Une fois le code généré dans l'application, entrez-le dans le champ "Code 2FA" ci-dessous.</li>
            </ul>

            <img
              src={qrCode}
              alt="QR Code 2FA"
              className="mx-auto my-6 w-48 h-48 lg:w-64 lg:h-64 shadow-md rounded-lg"
            />

            <p className="text-lg font-semibold text-gray-800">Ou entrez cette clé :</p>
            <p className="bg-gray-100 text-lg p-4 rounded-lg shadow-md mx-auto max-w-full overflow-x-auto break-all text-center">
              {secret}
            </p>
          </div>
        ) : (
          <div className="text-center">
            <p className="text-xl lg:text-2xl font-semibold text-gray-800">
              Double authentification déjà activée.
            </p>
            <p className="text-xl lg:text-2xl font-semibold text-gray-800 mt-2">
              Saisissez le code depuis votre application.
            </p>
          </div>
        )}
        <input type="text" name="twoFactorCode" placeholder="Code 2FA" onChange={handle2FAChange} value={twoFactorCode}
          className="border-2 border-blue-300 rounded-lg p-2 my-4 w-full" />
        <label className="flex items-center justify-center mt-2">
          <input type="checkbox" onChange={handleRememberDevice} checked={rememberDevice} className="mr-2" /> Se souvenir de cet appareil
        </label>
        <button type="submit" className="bg-bleuSTB hover:bg-marronSTB  text-white py-2 px-4 rounded-lg mt-4">Vérifier</button>
        <button onClick={() => setTempToken(null)} className="mt-4 text-bleuSTB hover:underline">
            ← Retour à la connexion
          </button>
      </form>
    )}
    <ForgotPassword isShowing={isForgotPasswordModalShowing} hide={toggleForgotPasswordModal} />
  </div>
);

}

