import React, { useState, useEffect } from "react";
import axios from "axios";
import { API_URL } from "../constants";
import { useHistory } from "react-router-dom";

export default function EditUserForm({ id, rafraichir, token, admin }) {
  const [newUser, setNewUser] = useState({ mail: "" });
  const [messageMailInvalide, setMessageMailInvalide] = useState("");
  const [messageUpdateMail, setMessageUpdateMail] = useState("");
  const [twoFactorEnabled, setTwoFactorEnabled] = useState(false);
  const navigate = useHistory();

  useEffect(() => {
    setTimeout(() => {
      setMessageMailInvalide("");
      setMessageUpdateMail("");
    }, 5000);
  }, [messageMailInvalide, messageUpdateMail]);

  // ✅ Récupérer et filtrer l'utilisateur correspondant
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const res = await axios.get(`${API_URL}/user`, {
          headers: { Authorization: "Bearer " + token },
        });

        // 🔹 Filtrer l'utilisateur par `id`
        const user = res.data.find((u) => u._id === id);
        if (!user) {
          setMessageMailInvalide("Utilisateur introuvable");
          return;
        }

        setTwoFactorEnabled(user.twoFactorEnabled);
        setNewUser({ mail: user.email });
      } catch (error) {
        console.error("Erreur récupération utilisateur:", error);
      }
    };

    if (id) fetchUserData();
  }, [id, token]);

  const handleChange = (event) => {
    const { name, value, checked, type } = event.target;
    if (type === "checkbox") {
      setTwoFactorEnabled(checked);
      toggle2FA(checked);
    } else {
      setNewUser({ ...newUser, [name]: value });
    }
  };

  const toggle2FA = async (enable) => {
    try {
        const res = await axios.patch(
            `${API_URL}/user/update`,
            { userId: id, twoFactorEnabled: enable },
            { headers: { Authorization: "Bearer " + token } }
        );

        setMessageUpdateMail(res.data.message);
        rafraichir();
    } catch (error) {
        setMessageMailInvalide(error.response?.data?.error || "Une erreur est survenue");
    }
};


  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(newUser.mail)) {
      setMessageMailInvalide("Adresse e-mail invalide");
      return;
    }

    try {
      const res = await axios.patch(
        `${API_URL}/user/update`,
        { userId: id, email: newUser.mail, admin },
        { headers: { Authorization: "Bearer " + token } }
      );
      setMessageUpdateMail(res.data.message);
      rafraichir();
    } catch (error) {
      setMessageMailInvalide(error.response?.data?.error || "Une erreur est survenue");
    }
  };

  return (
    <form className="flex flex-col items-center w-full max-w-lg mx-auto px-4 sm:px-6 lg:px-8" onSubmit={handleSubmit}>
      <label className="flex items-center md:text-base space-x-3 mt-4">
        <input type="checkbox" name="twoFactorEnabled" checked={twoFactorEnabled} onChange={handleChange} />
        <span>{twoFactorEnabled ? "Désactiver la double authentification" : "Activer la double authentification"}</span>
      </label>

      <label className="w-full">
        <input
          className="w-full text-sm md:text-base lg:text-xl border-2 border-bleuCielSTB focus:border-bleuSTB rounded-3xl p-3 text-center mt-4 mb-4"
          onChange={handleChange}
          value={newUser.mail}
          placeholder="Nouvelle adresse mail"
          name="mail"
          type="email"
        />
      </label>

      <input
        type="submit"
        value="Changer l'adresse mail"
        className="bg-bleuSTB hover:bg-marronSTB text-white text-sm md:text-base lg:text-lg xl:text-xl rounded-full py-2 px-6 cursor-pointer transition-all duration-300"
      />

      {messageMailInvalide && (
        <p className="bg-red-500 text-white text-sm md:text-base lg:text-lg xl:text-xl rounded-lg px-4 py-2 mt-4">
          {messageMailInvalide}
        </p>
      )}
      {messageUpdateMail && (
        <p className="bg-green-500 text-white text-sm md:text-base lg:text-lg xl:text-xl rounded-lg px-4 py-2 mt-4">
          {messageUpdateMail}
        </p>
      )}
    </form>
  );
}
